<template>
    <div class="order">
        <Breadcrumb :currentPage="currentPage"></Breadcrumb>
        <TitleTabs :tabsData="tabsData" @changeTab="changeTab"></TitleTabs>
        <TabItems @changeItem="changeItem" :tabCurrentIndex="secondTabCurrentIndex"></TabItems>
        <Introduce @changeDownload="changeDownload" v-if="secondTabCurrentIndex===0" :introduce="introduceData"></Introduce>
        <Teach v-else-if="secondTabCurrentIndex===1" :teachData="teachData"></Teach>
        <Problem v-else-if="secondTabCurrentIndex===2" :problemData="problemData"></Problem>
        <Download v-else-if="secondTabCurrentIndex===3" :downloadData="downloadData"></Download>
    </div>
</template>

<script>
import Breadcrumb  from '../../components/Breadcrumb'
import TitleTabs from '../../components/TitleTabs'
import TabItems from '../../components/TabItems'
import Introduce from '../../components/Introduce'
import Teach from '../../components/Teach'
import Problem from '../../components/Problem'
import Download from '../../components/Download'
export default {
    name: 'Order',

    data() {
        return {
            currentPage: '订单管理',
            firstTabCurrentIndex: 0,

            tabsData: [],
            secondTabCurrentIndex: 0,

            introduceData: {},

            teachData: {},

            problemData: {},

            downloadData: {}
        };
    },

    mounted() {
        this.getTitleTabs() 
        this.getCaigouIntroduce()
    },

    methods: {
        toOrder() {
            this.$router.replace('/Product')
        },

        changeDownload() {
            this.secondTabCurrentIndex = 3
            if(this.firstTabCurrentIndex === 0) {
                this.getCaigouDownload()
            }else if(this.firstTabCurrentIndex === 1) {
                this.getFahuoDownload()
            }

        },
        changeTab(firstIndex) {
            if(firstIndex === 0) {
                this.firstTabCurrentIndex = 0
                if(this.secondTabCurrentIndex === 0) {
                    this.secondTabCurrentIndex = 0
                    this.getCaigouIntroduce()
                }else if(this.secondTabCurrentIndex === 1) {
                    this.secondTabCurrentIndex = 1
                    this.getCaigouTeach()
                }else if(this.secondTabCurrentIndex === 2) {
                    this.secondTabCurrentIndex = 2
                    this.getCaigouProblem()
                }else if(this.secondTabCurrentIndex === 3) {
                    this.secondTabCurrentIndex = 3
                    this.getCaigouDownload()
                } 
            }else if (firstIndex === 1) {
                this.firstTabCurrentIndex = 1
                if(this.secondTabCurrentIndex === 0) {
                    this.secondTabCurrentIndex = 0
                    this.getFahuoIntroduce()
                }else if(this.secondTabCurrentIndex === 1) {
                    this.secondTabCurrentIndex = 1
                    this.getFahuoTeach()
                }else if(this.secondTabCurrentIndex === 2) {
                    this.secondTabCurrentIndex = 2
                    this.getFahuoProblem()
                }else if(this.secondTabCurrentIndex === 3) {
                    this.secondTabCurrentIndex = 3
                    this.getFahuoDownload()
                } 
            }
        },

        changeItem(secondIndex) {
            this.secondTabCurrentIndex = secondIndex

            if(this.firstTabCurrentIndex === 0) {
                if(this.secondTabCurrentIndex === 0) {
                    this.secondTabCurrentIndex = 0
                    this.getCaigouIntroduce()
                }else if(this.secondTabCurrentIndex === 1) {
                    this.secondTabCurrentIndex = 1
                    this.getCaigouTeach()
                }else if(this.secondTabCurrentIndex === 2) {
                    this.secondTabCurrentIndex = 2
                    this.getCaigouProblem()
                }else if(this.secondTabCurrentIndex === 3) {
                    this.secondTabCurrentIndex = 3
                    this.getCaigouDownload()
                } 
            }else if(this.firstTabCurrentIndex === 1){
                if(this.secondTabCurrentIndex === 0) {
                    this.secondTabCurrentIndex = 0
                    this.getFahuoIntroduce()
                }else if(this.secondTabCurrentIndex === 1) {
                    this.secondTabCurrentIndex = 1
                    this.getFahuoTeach()
                }else if(this.secondTabCurrentIndex === 2) {
                    this.secondTabCurrentIndex = 2
                    this.getFahuoProblem()
                }
                 else if(this.secondTabCurrentIndex === 3) {
                    this.secondTabCurrentIndex = 3
                    this.getFahuoDownload()
                } 
            }
        },

        async getTitleTabs() {
            const { code, data } =  await this.$http.get('/orderTabs')
            if(code === 200) {
                this.tabsData = data
            }

            this.secondTabCurrentIndex = 0
            this.firstTabCurrentIndex = 0
        },

        async getCaigouDownload() {
            const { code, data } = await this.$http.get('/caigouDownload')
            if(code === 200) {
                this.downloadData = data
            }
        },

        async getFahuoDownload() {
            const { code, data } = await this.$http.get('/fahuoDownload')
            if(code === 200) {
                this.downloadData = data
            }
        },

        async getCaigouProblem() {
            const { code, data } = await this.$http.get('/caigouProblem')
            if (code === 200) {
                this.problemData = data
            }
        },

        async getFahuoProblem() {
            const { code, data } = await this.$http.get('/fahuoProblem')
            if (code === 200) {
                this.problemData = data
            }
        },

        async getCaigouTeach() {
            const { code, data } = await this.$http.get('/caigouTeach')
            if (code === 200) {
                this.teachData = data
            }
        },

        async getFahuoTeach() {
            const { code, data } = await this.$http.get('/fahuoTeach')
            console.log('data: ', data);
            if (code === 200) {
                this.teachData = data
            }
        },

        async getCaigouIntroduce() {
            const { code, data } = await this.$http.get('/caigouIntroduce')
            if(code === 200) {
                this.introduceData = data
            }
        },

        async getFahuoIntroduce() {
            const { code, data} = await this.$http.get('/fahuoIntroduce')
            if(code === 200) {
                this.introduceData = data
            }
        }
    },

    components: {
        Breadcrumb,
        TitleTabs,
        TabItems,
        Introduce,
        Teach,
        Problem,
        Download
    },
};
</script>

<style lang="less" scoped>

</style>